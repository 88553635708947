// @import 'bootstrap-variables';
@import './_variables';
@import './_cards';
@import './colors';

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

body {
  margin: 0;
  font-family: 'proxima-nova', 'Avenir Next Web', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #212529;
  background: #f5f5f5;
  background-color: #f5f5f5;
}

a {
  color: #1D4ED8;
}

a:link {
  color: #1D4ED8;
  text-decoration: none;
}

a:visited {
  color: #1D4ED8;
  text-decoration: none;
}


a:active {
  color: #1D4ED8;
  text-decoration: none;
}

a:hover {
  color: #1D4ED8;
  text-decoration: none;
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Main page styles
========================================================================== */



/* wait autoprefixer update to allow simple generation of high pixel density media query */



/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */

.valid[required],
.valid.required {
  border-left: 5px solid green;
}

.invalid:not(form) {
  border-left: 5px solid red;
}

/* other generic styles */

.jh-card {
  padding: 1.5%;
  margin-top: 20px;
  border: none;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */

.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .toast {
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */

@mixin no-padding($side) {
  @if $side== 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */

.width-min {
  width: 1% !important;
}

/* ==========================================================================
entity detail page css
========================================================================== */

.row.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }
    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
entity Organization and user page responsive
========================================================================== */

@media screen and (max-width: 550px) and (min-width: 100px) {
  .entity-text-hide {
    display: none;
  }
  .entity-button-icn {
    min-width: 35px !important;
    padding: 0 0px !important;
  }
  .icn-align {
    margin-left: 8px !important;
  }
  .clearfix {
    display: grid !important;
    text-align: center;
  }
  .wizard-btn {
    margin-top: 10px !important;
  }
  .entity-userDet-align {
    display: flow-root;
    font-size: 13px;
  }
  .entity-md-search {
    width: 90%;
    margin-left: 15px;
  }
  .org-detail-txt {
    font-size: 19px !important;
  }
}
@media screen and (min-width: 760px) {
  .entity-md-search {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .txt-hide {
    display: none;
  }
  .impusr-button-icn {
    min-width: 35px !important;
    padding: 0 0px !important;
  }
  .top-tit-txt {
    font-size: 20px !important;
  }
}
.subscrpt-header-align {
  white-space: nowrap;
}
@media screen and (max-width: 360px) {
  .set-xs-txt {
    font-size: 15px !important;
  }
  .tittxt-xs-align {
    font-size: 18px !important;
  }
  .subtxt-xs-align {
    font-size: 11px !important;
  }
  .top-tit-txt {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 415px) and (min-width: 380px) {
  .tittxt-xs-align {
    font-size: 25px !important;
  }
  .subtxt-xs-align {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1280px) and (min-width: 640px) {
  .top-tit-txt {
    font-size: 11px !important;
  }
  .home-button-icn {
    right: 25px !important;
  }
}
@media screen and (max-width: 760px) and (min-width: 550px) {
  .entity-text-hide {
    display: none;
  }
  .entity-button-icn {
    min-width: 35px !important;
    padding: 0 0px !important;
  }
  .icn-align {
    margin-left: 8px !important;
  }
  .entity-md-search {
    width: 90%;
    margin-left: 15px;
  }
}
@media screen and (max-width: 330px) {
  .top-tit-txt {
    font-size: 8px !important;
  }
}
/* ==========================================================================
ui bootstrap tweaks
========================================================================== */

.background-default {
  background: #F9FAFB !important;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  min-height: 100%;
}

.background-pages {
  background: #F9FAFB !important;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  min-height: 100%;
}

.nav,
.pagination,
.carousel,
.card-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-secondary,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-secondary {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.logo {
  max-width: 200px;
  margin: 5px auto 5px;
}

.card-white {
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  -webkit-box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
  box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
  background-repeat: no-repeat;
  color: rgba(0, 0, 0, 0.6);
}

.card-orange {
  background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
  -webkit-box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
  box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
  background-repeat: no-repeat;
  color: #e6e9f0;
}

.theme--light.v-application {
  background: #f4f4f4;
  color: rgba(0, 0, 0, 0.87);
}

.entity-title {
  font-family: 'proxima-nova', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;
  font-feature-settings: 'pnum';
  font-variant: proportional-nums;
  white-space: normal;
  line-height: 32px;
  font-weight: 700;
  font-size: 28px;
  display: inline;
  color: #1a1f36;
}

.entity-button-text {
  font-family: 'proxima-nova', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;
  font-feature-settings: 'pnum';
  font-variant: proportional-nums;
  white-space: nowrap;
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #3c4257;
  text-transform: none;
}

.trial-text {
  background-color: white;
  color: #2463EB !important;
}

.swal-wide{
  width:900px !important;
}

.free-card {
  background: linear-gradient(180deg, rgba(42, 42, 42, 0.4) 6.38%, rgba(235, 235, 235, 0.4) 100%);
}

.text-sub-description {
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
  color: #666;
  overflow: auto;
}

.alert-warning {
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}

.card-back {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 350px;
  text-align: center;
  z-index: 0;
  background: rgb(255, 115, 60);
  background-image: linear-gradient(46deg, #ff4800 0%, #ff9a44 100%);
  //background-image: linear-gradient(46deg, rgb(0, 172, 126) 0%, rgb(81, 204, 38) 100%);
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 15px 0px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
}

.top-nav-public {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 5px 0px, rgba(0, 0, 0, 0.04) 0px 1px 0px 0px;
  display: flex;
  height: 80px;
  -webkit-box-pack: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 10;
}

.logo-size {
  width: 90px;
}

.grecaptcha-badge {
  opacity: 0;
}

.v-application {
  font-family: 'proxima-nova', Roboto, sans-serif;
  // background: url('../images/login_bg_fractal_pattern.png') !important;
  // background-size: cover;
  // height: 100vh;
}

.input-label {
  color: #6B7280;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.row {
  margin-top: 1px;
}

.v-card {
  padding-bottom: 10px;
}

.text-danger {
  color: #DC2626;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  transition: background-color 5000s ease-in-out 0s;
}

.search-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: #6B7280;
}

.button-font {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding-top: 3px;
}

.home-container {
  padding-left: 18%;
  padding-right: 18%;
}

.home-textarea {
  width: 100%;
  padding: 10px 58px 58px 8px;
}

.home-textarea:focus {
  outline: none;
}

.home-textarea::placeholder {
  color: #9CA3AF;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
}

.project-title {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  color: #6B7280;

}

.project-card {
  width: 151px;
  height: 124px;
  padding: 32px 12px 32px 12px;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  cursor: pointer;
}

.project-content {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  margin-top: -16px;
  color: #FFFFFF;
}

.games-card {
  line-height: 17.28px;
  border: 2px solid #DCA820;
  background: #FBBF24;
}

.arcades-card {
  line-height: 17.28px;
  border: 2px solid #068F65;
  background: #09AD7A;
}

.vr-card {
  line-height: 30px;
  border: 2px solid #DD1755;
  background: #FE1E64;
}

.courses-card {
  line-height: 17.28px;
  border: 2px solid #DB3E00;
  background: #FF4800;
}

.ml-card {
  line-height: 30px;
  border: 2px solid #0097B6;
  background: #00B4D9;
}

.video-card {
  line-height: 30px;
  border: 2px solid #2E3CB1;
  background: #4E60F6;
}

.ai-card {
  line-height: 30px;
  border: 2px solid #ff6666;
  background: #ff8080;
}

.asset-card {
  line-height: 30px;
  border: 2px solid #a64dff;
  background: #b366ff;
}

.avatar-card {
  line-height: 30px;
  border: 2px solid #5c5c3d;
  background: #6b6b47;
}

.text-card {
  line-height: 30px;
  border: 2px solid #3366cc;
  background: #3366cc;
}

.analogy-card {
  line-height: 30px;
  border: 2px solid #b38600;
  background: #cc9900;
}

.analogy-card {
  line-height: 30px;
  border: 2px solid #b38600;
  background: #cc9900;
}

.question-card {
  line-height: 30px;
  border: 2px solid #993366;
  background: #ac3973;
}

.steps-card {
  line-height: 30px;
  border: 2px solid #52527a;
  background: #5c5c8a;
}

.steps-card {
  line-height: 30px;
  border: 2px solid #52527a;
  background: #5c5c8a;
}

.bg-card {
  line-height: 30px;
  border: 2px solid #862d2d;
  background: #993333;
}

.speech-card {
  line-height: 30px;
  border: 2px solid #4080bf;
  background: #538cc6;
}

.home-plus {
  position: relative;
  top: 30px;
  left: 40px;
  border-radius: 28.24px;
  width: 24px;
  height: 24px;
  padding: 4px;
}

.game-plus {
  top: 50px;
  left: 25px;
  background-color: #DCA720;
  border: 0.02px solid #DCA720;
}

.arcades-plus {
  top: 50px;
  left: 25px;
  background-color: #068F65;
  border: 0.02px solid #068F65;
}

.vr-plus {
  top: 20px;
  left: 25px;
  background-color: #DD1755;
  border: 0.02px solid #DD1755;
}

.courses-plus {
  top: 50px;
  left: 25px;
  background-color: #DB3E00;
  border: 0.02px solid #DB3E00;
}

.ml-plus {
  top: 20px;
  left: 24px;
  background-color: #0097B6;
  border: 0.02px solid #0097B6;
}

.video-plus {
  top: 20px;
  left: 24px;
  background-color: #2E3CB1;
  border: 0.02px solid #2E3CB1;
}

.ai-plus {
  top: 50px;
  left: 24px;
  background-color: #ff6666;
  border: 0.02px solid #ff6666;
}

.asset-plus {
  top: 20px;
  left: 24px;
  background-color: #a64dff;
  border: 0.02px solid #a64dff;
}

.avatar-plus {
  top: 20px;
  left: 24px;
  background-color: #5c5c3d;
  border: 0.02px solid #5c5c3d;
}

.text-plus {
  top: 20px;
  left: 24px;
  background-color: #2e5cb8;
  border: 0.02px solid #2e5cb8;
}

.analogy-plus {
  top: 20px;
  left: 24px;
  background-color: #b38600;
  border: 0.02px solid #b38600;
}

.question-plus {
  top: 20px;
  left: 24px;
  background-color: #993366;
  border: 0.02px solid #993366;
}

.bg-plus {
  top: 20px;
  left: 24px;
  background-color: #862d2d;
  border: 0.02px solid #862d2d;
}

.steps-plus {
  top: 20px;
  left: 24px;
  background-color: #52527a;
  border: 0.02px solid #52527a;
}

.speech-plus {
  top: 20px;
  left: 24px;
  background-color: #4080bf;
  border: 0.02px solid #4080bf;
}

.left-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-transform: capitalize;
  padding: 16px;
  padding-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capitalize {
  text-transform: capitalize;
}

.left-content {
  font-size: 10px;
  font-weight: 400;
  line-height: 12.5px;
  text-align: left;
  color: #6B7280;
  display: inline-block;
  margin-left: 4px;
}

.left-card {
  border-radius: 4px;
  border: 1px solid#D1D5DB;
  cursor: pointer;
}

.left-panel {
  padding-bottom: 8px;
  gap: 2px;
}

.home-search {
  font-weight: 400;
  width: 100%;
  height: 32px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #D1D5DB;
}

.pl-2 {
  padding-left: 2px;
}

.home-search:focus {
  line-height: 21px;
  text-align: left;
  padding-left: 8px;
  border: 1px solid #D1D5DB
}

.home {
  font-family: "Inter";
}

.dropbtn {
  background-color: #FF4800;
  color: white;
  width: 143px;
  height: 32px;
  gap: 12px;
  border-radius: 4px;
  cursor: pointer;
  }

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 144px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #FF4800;
}

.btn-padding {
  padding-top: 5px;
  padding-left: 10px;
}

.sub-text {
  margin-top: -2px;
}

.article-container {
  max-height: 400px;
  overflow:scroll;
}

.float-right {
  float: right;
}

.checkbox-text {
  text-align: left;
  float: left;
  margin: 8px;
}

.padding-right-4 {
  padding-right: 4px;
}

.ai-text {
  font-size: 14px;
  color: #6B7280;
  margin-top: 1px;
}

.ai-suggestion-text {
  margin-right: 80px;
  display: contents;
}

.search {
  border-radius: 4px;
  border: 1px solid #D1D5DB;
  margin: 12px;
  width: 100%;
}

.margin-right-8 {
  margin-right: 8px;
}

.v-data-table tr td .capitalize {
  text-transform: capitalize;
}

.disclaimer-text {
  display: inline-block;
  font-size: .7em;
  line-height: 1.1em; /* Adjust line height as needed */
  color: #888; /* Light gray color for disclaimer text */
}

.suggestion {
  padding-right: 12px;
  padding-bottom: 9px;
  float: right;
}

.suggestion-text {
  margin-top: 4px;
  color: #6B7280;
  font-size: 12px;
  margin-right: 12px;
}

.modify-search {
  font-weight: normal;
  font-size: 14px;
}

.default-article {
  width: 100%;
}

.padding-right-16 {
  padding-right: 12px;
}

.thumbnail {
  overflow: hidden;
  max-height: 100px;
}

.label-container {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  left: -4px;
}

.checkbox-content {
  min-width: 225px;
}

.product-card {
  border-radius: 8px !important;
  border: 1px solid #D9D9D9 !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.product-card-text {
  margin: 12px 16px 36px 0px !important;
  
  @media (min-width: 600px) {
    margin: 12px 24px 36px 24px !important;
  }
  
  @media (min-width: 960px) {
    margin: 12px 0px 36px 48px !important;
  }
}

.product-card-title {
  font-weight: 600;
  margin: 12px 16px 0px 16px !important;
  
  @media (min-width: 600px) {
    margin: 12px 24px 0px 24px !important;
  }
  
  @media (min-width: 960px) {
    margin: 12px 0px 0px 48px !important;
  }
}

.margin-card {
  margin-top: 16px;
  
  @media (min-width: 600px) {
    margin-top: 20px;
  }
  
  @media (min-width: 960px) {
    margin-top: 24px;
  }
}

.product-heading {
  font-weight: 600;
  font-size: 20px;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay-spinner {
  position: relative;
}